import './LineSepar.css'

function LineSepar() {
  return (
   
    <div className='line-Separ'></div>
 
  );
}

export default LineSepar;
