import React from 'react';
import "./MyMenu.css";
import BtnIndice from '../BtnIndice/BtnIndice';
import { Link } from 'react-router-dom';
import { scrollToTop } from "../../../assets/main";

function MyMenu({ toggleMenu }) {
  return (
    <ul className='nav-list'>
      <Link to={"/"} onClick={() => {toggleMenu(); scrollToTop();}}><BtnIndice text={'Inicio'}  /></Link>
      <Link to={"/noticias"} onClick={() => {toggleMenu(); scrollToTop();}}> <BtnIndice text={'Noticias'} /></Link>
      <Link to={"/asesoria-legal"} onClick={() => {toggleMenu(); scrollToTop();}}> <BtnIndice text={'Area de asesoramiento'} /></Link>
      <Link to={"/gestion"} onClick={() => {toggleMenu(); scrollToTop();}}> <BtnIndice text={'Area de gestión'} /></Link>
      <Link to={"/miembros"} onClick={() => {toggleMenu(); scrollToTop();}}> <BtnIndice text={'Miembros'} /></Link> 
      <Link to={"/faq"} onClick={() => {toggleMenu(); scrollToTop();}}> <BtnIndice text={'FAQ'} /></Link>
      <Link to={"/contacto"} onClick={() => {toggleMenu(); scrollToTop();}}> <BtnIndice text={'Contacto'} /></Link>
    </ul>
  );
}

export default MyMenu;
