import './WhatsappAccesor.css'
import {WhatsappLink} from './WhatsappLink'
function WhatsappAccesor() {

  return (
    <>
    <div id="Whatsapp" className="WhatsappI">
        <WhatsappLink img={<img src="/img/icon-whatsapp.png" alt="Icono Whatsapp"/>}/>
 
    </div>

    </>
  );
}

export default WhatsappAccesor;
