

function IcoLlamada({width="64",height="64"}) {
  return (
<svg width={width} height={height} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M22.625 3.031a3.41 3.41 0 0 0-.969.25V3.25c-.015.004-.047.027-.062.031c-2.434.856-7.094 3-11.313 7.219c-4.25 4.25-6.3 8.996-7.219 11.344v.031a3.59 3.59 0 0 0 .688 3.469l.031.031v.031l3 2.969l.125.125c.828.828 2.266.828 3.094 0l4.094-4.094c.828-.828.828-2.297 0-3.125l-1.969-1.968c.344-.715 1.234-2.473 2.813-4.125C16.503 13.546 18.3 12.71 19 12.405l2.063 2.063l.093.062c.469.313 1.004.489 1.563.469c.523-.02 1.066-.277 1.468-.719l.032.031l.062-.093l4.032-4c.828-.828.828-2.297 0-3.125L25.218 4a3.438 3.438 0 0 0-1.657-.906a3.006 3.006 0 0 0-.937-.063zm.156 2c.38-.027.758.133 1 .375L26.906 8.5c.172.172.172.11 0 .281L22.72 12.97c.004-.004 0 .027-.094.031c-.078.004-.195-.063-.313-.125h-.062L19.906 10.5l-.468-.469l-.594.219s-3.074 1.148-5.375 3.563c-2.262 2.367-3.5 5.28-3.5 5.28l-.25.626l.469.5l2.5 2.5c.171.172.171.11 0 .281l-4.094 4.094c-.172.172-.11.172-.281 0L5.25 24.062c-.008-.007.008-.023 0-.03L5.219 24c-.348-.434-.453-.895-.282-1.438c.004-.011-.003-.019 0-.03c.887-2.259 2.84-6.684 6.782-10.626c3.957-3.957 8.445-5.992 10.625-6.75l.031-.031h.031c.121-.055.25-.086.375-.094"/>
</svg>
  );
}

export default IcoLlamada;
