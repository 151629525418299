import './../Subtitles.css'

function Subtitleh3({text}) {
  return (
    <>
      <h3 className="Subtitle-h3">{text}</h3>
    </>
  );
}

export default Subtitleh3;
