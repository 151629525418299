import './MiniLineSepar.css'

function MiniLineSepar() {
  return (
    
    <div className='min-line-Separ'></div>
   
  );
}

export default MiniLineSepar;
