
import './BtnIndice.css'

function BtnIndice({text}) {
  //const texto= text.toUpperCase()
  return (
    <>
      <li >
                       
        <p className='btnindicep'>{text}</p>
                   
        </li>
    </>
  );
}


export default BtnIndice;
